import Card from 'react-bootstrap/Card';
import './assets/movie.css';
//import Card from 'react-bootstrap/Card';
function Movie(props) {
    return(
    
        <Card id={props.key} style={{ width: '20rem' }}>
        <Card.Img variant="top" alt={props.imgAlt} src={props.url} />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>
            Release Date: {props.releaseYear}
          </Card.Text>
        </Card.Body>
      </Card>
    );
}

export default Movie